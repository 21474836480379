
import { ref, reactive, defineComponent, computed, watch } from "vue"
import { useId } from "@/composable/useId"

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
    },
    right: {
      type: Boolean,
    },
    timeout: {
      type: Number,
      default: 0,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const tooltipClasses = computed(() =>
      props.right
        ? ["right-0", "after-arrow-right"]
        : ["left-0", "after-arrow-left"]
    )

    const closeTooltip = () => emit("close")
    let timeout: NodeJS.Timeout
    watch(
      () => props.show,
      show => {
        if (show) {
          if (props.timeout) timeout = setTimeout(closeTooltip, props.timeout)
        } else if (timeout) clearTimeout(timeout)
      },
      {
        immediate: true,
      }
    )

    const { id } = useId()

    return { tooltipClasses, closeTooltip, id }
  },
})
