
import { defineComponent } from "vue"
import { Service } from "@/composable/useDefinitions"
import { useColor } from "@/composable/useColor"
import { useId } from "@/composable/useId"

export default defineComponent({
  props: {
    link: {
      required: true,
      type: Object as () => Service["links"][number],
    },
    brand: {
      required: true,
      type: Object as () => Service["brand"],
    },
  },
  setup(props) {
    const { contentStyle: brandOnClorStyle } = useColor(props.brand.onColor, 1)
    const { backgroundStyle: brandBackgroundStyle } = useColor(
      props.brand.color,
    )
    const { id: descriptionId } = useId()
    return {
      brandBackgroundStyle,
      brandOnClorStyle,
      descriptionId,
    }
  },
})
