
import { defineComponent, onBeforeMount } from "vue"
import { usePrefersColorScheme } from "@/composable/usePrefersColorScheme"
export default defineComponent({
  setup() {
    const { initializeFromPrefersColorScheme } = usePrefersColorScheme()
    onBeforeMount(() => {
      initializeFromPrefersColorScheme()
    })
  },
})
