
import { ref, reactive, defineComponent, computed } from "vue"
import { usePrefersColorScheme } from "@/composable/usePrefersColorScheme"
import { Render } from "@/composable/useDefinitions"

export default defineComponent({
  props: {
    render: {
      required: true,
      type: Object as () => Render,
    },
  },
  setup(props) {
    const { isLight, setColorScheme } = usePrefersColorScheme()
    const renderButtonLabels = computed(
      () => props.render.interface.controls.colorScheme.buttonLabel,
    )
    const label = computed(() =>
      isLight.value
        ? renderButtonLabels.value.changeToDarkMode
        : renderButtonLabels.value.changeToLightMode,
    )

    return { isLight, setColorScheme, label }
  },
})
