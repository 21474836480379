
import {
  ref,
  defineComponent,
  computed,
  defineAsyncComponent,
  reactive,
} from "vue"
import MenuContainerContent from "./MenuContainerContent.vue"
import ButtonToggleColorScheme from "./ButtonToggleColorScheme.vue"
const ButtonNewVersion = defineAsyncComponent(() =>
  import("./ButtonNewVersion.vue"),
)
import { Render } from "@/composable/useDefinitions"
import { onBeforeRouteUpdate } from "vue-router"
import { useVersionControl } from "@/composable/useVersionControl"
import { useId } from "@/composable/useId"

export default defineComponent({
  components: {
    MenuContainerContent,
    ButtonToggleColorScheme,
    ButtonNewVersion,
  },
  props: {
    render: {
      required: true,
      type: Object as () => Render,
    },
  },
  setup(props) {
    const mobileShowContent = ref(true)
    onBeforeRouteUpdate(() => {
      mobileShowContent.value = false
    })

    const header = computed(() => props.render.interface.header)
    const controls = computed(() => props.render.interface.controls)

    const { serviceWorkerWaiting } = useVersionControl()

    const navigationIds = reactive({
      lastLink: useId().id,
      toggleButton: useId().id,
    })

    const controlsHeadingId = useId().id
    return {
      mobileShowContent,
      header,
      navigationIds,
      controls,
      serviceWorkerWaiting,
      controlsHeadingId,
    }
  },
})
