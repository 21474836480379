
import { ref, reactive, defineComponent } from "vue"
import { Service } from "@/composable/useDefinitions"
import ServiceCardLink from "./ServiceCardLink.vue"
import { useColor } from "@/composable/useColor"

export default defineComponent({
  components: {
    ServiceCardLink,
  },
  props: {
    service: {
      required: true,
      type: Object as () => Service,
    },
  },
  setup(props) {
    const { backgroundStyle: brandBackgroundStyle } = useColor(
      props.service.brand.color
    )
    return { brandBackgroundStyle }
  },
})
