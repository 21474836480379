
import { ref, reactive, defineComponent } from "vue"

export default defineComponent({
  props: {
    duration: {
      type: String,
      default: "duration-200",
      validator: duration =>
        typeof duration === "string" && duration.startsWith("duration-"),
    },
  },
  setup() {
    return {}
  },
})
