
import { ref, reactive, defineComponent } from "vue"
import CategoryContainerItem from "./CategoryContainerItem.vue"
import { Category } from "@/composable/useDefinitions"
export default defineComponent({
  components: {
    CategoryContainerItem,
  },
  props: {
    render: {
      required: true,
      type: Array as () => Category[],
    },
  },
  // setup() {
  //   return {}
  // },
})
