<template>
  <svg viewBox="0 0 63 51" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" v-if="icon === 'link'" v-bind:svg-inline="''" v-bind:class="'fill-current fill-inherit pointer-events-none'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'" v-bind:aria-hidden="'true'"><path d="M39.401 4.96a.995.995 0 01.995.995v3.038a.994.994 0 01-.995.995H12.742A7.546 7.546 0 005.2 17.531v20.112a7.546 7.546 0 007.542 7.542h32.682a7.546 7.546 0 007.542-7.542V23.554c0-.55.446-.995.995-.995h3.038c.55 0 .995.445.995.995v14.089c0 6.938-5.632 12.57-12.57 12.57H12.742c-6.938 0-12.57-5.632-12.57-12.57V17.531c0-6.938 5.632-12.571 12.57-12.571h26.659zM61.647-.003a1.312 1.312 0 011.311 1.311v12.14c0 .555-.45 1.005-1.005 1.005h-3.002a1.008 1.008 0 01-1.005-1.005V8.646a.038.038 0 00-.024-.036.037.037 0 00-.042.009L38.3 28.198a1.001 1.001 0 01-1.42 0l-2.123-2.123a1.002 1.002 0 010-1.421L54.336 5.075a.039.039 0 00.009-.042.039.039 0 00-.036-.024h-4.803a1.004 1.004 0 01-1.004-1.005V1.001c0-.554.45-1.004 1.004-1.004h12.141z" fill="#fff"/></svg>
  <svg viewBox="0 0 38 37" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" v-else-if="icon === 'close'" v-bind:svg-inline="''" v-bind:class="'fill-current fill-inherit pointer-events-none'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'" v-bind:aria-hidden="'true'"><path d="M23.674 17.415a1.6 1.6 0 000 2.258l13.089 13.088a2.394 2.394 0 010 3.386l-.149.149a2.395 2.395 0 01-3.387 0L20.139 23.208a1.596 1.596 0 00-2.257 0L4.794 36.296a2.396 2.396 0 01-3.387 0l-.149-.149a2.394 2.394 0 010-3.386l13.088-13.088a1.596 1.596 0 000-2.258L1.258 4.327a2.394 2.394 0 010-3.386l.149-.149a2.395 2.395 0 013.387 0L17.882 13.88a1.596 1.596 0 002.257 0L33.227.792a2.395 2.395 0 013.387 0l.149.149a2.394 2.394 0 010 3.386L23.674 17.415z" fill="#fff"/></svg>
  <svg viewBox="0 0 47 49" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" v-else-if="icon === 'moon'" v-bind:svg-inline="''" v-bind:class="'fill-current fill-inherit pointer-events-none'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'" v-bind:aria-hidden="'true'"><path d="M44.507 35.024a1.86 1.86 0 012.015 2.824 24.895 24.895 0 01-8.106 7.489c-11.949 6.899-27.252 2.798-34.151-9.151-6.898-11.949-2.798-27.252 9.151-34.151A24.93 24.93 0 0116.651.466a1.872 1.872 0 012.365 2.583c-3.318 6.54-3.363 14.578.576 21.402 5.132 8.888 15.431 12.951 24.915 10.573z" fill="#fff"/></svg>
  <svg viewBox="0 0 51 51" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" v-else-if="icon === 'sun'" v-bind:svg-inline="''" v-bind:class="'fill-current fill-inherit pointer-events-none'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'" v-bind:aria-hidden="'true'"><path d="M22.51 48.228a2.5 2.5 0 005 0v-7.07c0-1.379-1.12-2.5-2.5-2.5a2.502 2.502 0 00-2.5 2.5v7.07zM7.333 39.87a2.501 2.501 0 003.535 3.536l4.999-4.999a2.501 2.501 0 00-3.535-3.536L7.333 39.87zm31.82 3.536a2.5 2.5 0 103.535-3.536l-4.999-4.999a2.503 2.503 0 00-3.536 0 2.503 2.503 0 000 3.536l5 4.999zM25.01 15.728c5.52 0 10 4.481 10 10s-4.48 10-10 10c-5.519 0-10-4.481-10-10s4.481-10 10-10zm-22.5 7.5c-1.379 0-2.5 1.12-2.5 2.5s1.121 2.5 2.5 2.5h7.07a2.5 2.5 0 000-5H2.51zm45 5a2.5 2.5 0 000-5h-7.07c-1.379 0-2.5 1.12-2.5 2.5s1.121 2.5 2.5 2.5h7.07zM10.868 8.051a2.5 2.5 0 00-3.535 3.535l4.999 4.999a2.5 2.5 0 003.535-3.535l-4.999-4.999zm31.82 3.535a2.5 2.5 0 00-3.535-3.535l-5 4.999a2.501 2.501 0 003.536 3.535l4.999-4.999zM27.51 3.228a2.5 2.5 0 00-5 0v7.07a2.5 2.5 0 005 0v-7.07z" fill="#fff"/></svg>
  <svg viewBox="0 0 11 51" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" v-else-if="icon === 'menu'" v-bind:svg-inline="''" v-bind:class="'fill-current fill-inherit pointer-events-none'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'" v-bind:aria-hidden="'true'"><path d="M5.01 40.564c2.76 0 5 2.24 5 5 0 2.759-2.24 5-5 5-2.759 0-5-2.241-5-5 0-2.76 2.241-5 5-5zm0-20c2.76 0 5 2.24 5 5 0 2.759-2.24 5-5 5-2.759 0-5-2.241-5-5 0-2.76 2.241-5 5-5zm0-20c2.76 0 5 2.24 5 5 0 2.759-2.24 5-5 5-2.759 0-5-2.241-5-5 0-2.76 2.241-5 5-5z" fill="#fff"/></svg>
  <svg viewBox="0 0 46 51" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" v-else-if="icon === 'download'" v-bind:svg-inline="''" v-bind:class="'fill-current fill-inherit pointer-events-none'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'" v-bind:aria-hidden="'true'"><path d="M45.51 47.941c0-1.379-1.12-2.5-2.5-2.5h-40a2.502 2.502 0 00-2.5 2.5c0 1.38 1.121 2.5 2.5 2.5h40a2.5 2.5 0 002.5-2.5zM20.086 36.554a.248.248 0 00.424-.175V2.941c0-1.379 1.121-2.5 2.5-2.5 1.38 0 2.5 1.121 2.5 2.5v33.365c0 .1.061.191.154.229.093.039.2.018.271-.053L36.17 26.246a2.501 2.501 0 013.536 3.535L24.832 44.655c-.25.25-.54.436-.85.558a2.502 2.502 0 01-2.812-.504L6.242 29.781a2.5 2.5 0 113.536-3.535l10.308 10.308z" fill="#fff"/></svg>
  <svg viewBox="0 0 46 50" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" v-else-if="icon === 'downloading'" v-bind:svg-inline="''" v-bind:class="'fill-current fill-inherit pointer-events-none'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'" v-bind:aria-hidden="'true'"><path d="M20.576 41.509V2.545a2.5 2.5 0 015 0v38.892l15.726-15.726a2.501 2.501 0 013.536 3.535l-19.94 19.94c-.25.25-.54.436-.85.558a2.502 2.502 0 01-2.812-.504L1.242 29.246a2.5 2.5 0 113.536-3.535l15.798 15.798z" fill="#fff"/></svg>
</template>

<script lang="ts">
import { ref, reactive, defineComponent } from "vue"

export default defineComponent({
  props: {
    icon: {
      type: String as () =>
        | "link"
        | "close"
        | "moon"
        | "sun"
        | "menu"
        | "download"
        | "downloading",
      required: true,
    },
  },
  setup() {
    return {}
  },
})
</script>
