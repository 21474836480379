
import { ref, reactive, defineComponent } from "vue"

export default defineComponent({
  props: {
    icon: {
      type: String as () =>
        | "link"
        | "close"
        | "moon"
        | "sun"
        | "menu"
        | "download"
        | "downloading",
      required: true,
    },
  },
  setup() {
    return {}
  },
})
