
import {
  ref,
  reactive,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  defineAsyncComponent,
} from "vue"
import { Render } from "@/composable/useDefinitions"
const MenuContainerMobile = defineAsyncComponent(() =>
  import("./MenuContainerMobile.vue")
)
const MenuContainerDesktop = defineAsyncComponent(() =>
  import("./MenuContainerDesktop.vue")
)
//TODO add global page loader based on state of this async component

export default defineComponent({
  components: {
    MenuContainerMobile,
    MenuContainerDesktop,
  },
  props: {
    render: {
      required: true,
      type: Object as () => Render,
    },
  },
  setup() {
    const mobileMode = ref(true)
    const setMode = () => {
      const width = window.innerWidth
      mobileMode.value = width < 768
    }
    setMode()
    onBeforeMount(() => {
      window.addEventListener("resize", setMode)
    })
    onBeforeUnmount(() => {
      window.removeEventListener("resize", setMode)
    })

    return { mobileMode }
  },
})
