
import {
  ref,
  reactive,
  defineComponent,
  defineAsyncComponent,
  computed,
} from "vue"
import MenuContainerContent from "./MenuContainerContent.vue"
import ButtonToggleColorScheme from "./ButtonToggleColorScheme.vue"
const ButtonNewVersion = defineAsyncComponent(() =>
  import("./ButtonNewVersion.vue"),
)
import { Render } from "@/composable/useDefinitions"
import { useVersionControl } from "@/composable/useVersionControl"
import TransitionFade from "./TransitionFade.vue"
import { useId } from "@/composable/useId"

export default defineComponent({
  components: {
    MenuContainerContent,
    ButtonToggleColorScheme,
    ButtonNewVersion,
    TransitionFade,
  },
  props: {
    render: {
      required: true,
      type: Object as () => Render,
    },
  },
  setup(props) {
    const header = computed(() => props.render.interface.header)
    const controls = computed(() => props.render.interface.controls)

    const { serviceWorkerWaiting } = useVersionControl()
    const controlsHeadingId = useId().id

    return { header, controls, serviceWorkerWaiting, controlsHeadingId }
  },
})
