
import { ref, reactive, defineComponent } from "vue"
import { Category } from "@/composable/useDefinitions"

interface NavigationFocusTargetsId {
  lastLink: string
  toggleButton: string
}

export default defineComponent({
  props: {
    render: {
      required: true,
      type: Array as () => Category[],
    },
    focusTargetsId: {
      default: () => ({
        lastLink: "",
        toggleButton: "",
      }),
      type: Object as () => NavigationFocusTargetsId,
    },
  },
  setup() {
    return {}
  },
})
