
import { ref, reactive, defineComponent, computed } from "vue"
import { useVersionControl } from "@/composable/useVersionControl"
import { useDefinitions } from "@/composable/useDefinitions"
export default defineComponent({
  props: {
    tooltipRight: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { skipWaiting: skip, skipping } = useVersionControl()
    const tooltipShow = ref(!skipping.value)

    const skipWaiting = () => {
      tooltipShow.value = false
      skip()
    }

    const { render } = useDefinitions()
    const tooltipText = computed(
      () => render.value.interface.controls.versionControl.tooltip,
    )

    return {
      skipWaiting,
      tooltipShow,
      tooltipText,
      skipping,
    }
  },
})
